%description-12 {
  font-size: 12px;
  font-family: $font-sauce;
  line-height: 1.3;
}

%description-16 {
  font-family: $font-sauce;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.3;

  @include media("<=tablet") {
    font-size: 3rem;
  }

  @include media("<=phone") {
    font-size: 16px;
  }
}

%description-32 {
  font-family: $font-sauce;
  font-size: 2.8rem;
  line-height: 1.3;
  letter-spacing: -0.025em;

  @include media("<=phone") {
    font-size: 20px;
    padding: 5%;
  }
}
