html {
  font-size: calc(100vw / 1440 * 10);
  overscroll-behavior: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  cursor: url("data:image/svg+xml,%3Csvg height='6' width='6' viewBox='0 0 8 8' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='4' cy='4' fill='%23eee' r='4'/%3E%3C/svg%3E") 3 3, auto;
  @include media("<=phone") {
    font-size: calc(100vw / 550 * 10);
  }

  @include media("<=375px") {
    font-size: calc(100vw / 375 * 10);
  }
}

// resets
body {
  font-family: $font-sauce, sans-serif;
  font-weight: 500;
  min-height: 100vh;
  font-size: 1.6rem;
  background: $col-white;
  color: $col-black;
}




.cursor {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: var(--width);
  height: var(--height);
  transform: translate(calc(var(--x) - var(--width) / 2), calc(var(--y) - var(--height) / 2));
  /* transition-duration: 125ms;
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-property: width, height, transform; */
  transition: 150ms width cubic-bezier(0.39, 0.575, 0.565, 1),
              150ms height cubic-bezier(0.39, 0.575, 0.565, 1),
              150ms transform cubic-bezier(0.39, 0.575, 0.565, 1);
  z-index: 1;
  pointer-events: none;
  will-change: transform;
}
@media (pointer: fine) {
  .cursor {
    display: block;
  }
}
.cursor::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: var(--radius);
  border: 2px solid hsl(359, 100%, 57%);
  opacity: var(--scale);
  -webkit-transform: scale(var(--scale));
          transform: scale(var(--scale));
  transition: 300ms opacity cubic-bezier(0.39, 0.575, 0.565, 1),
              300ms transform cubic-bezier(0.39, 0.575, 0.565, 1),
              150ms border-radius cubic-bezier(0.39, 0.575, 0.565, 1);
}

.icon{
width: 24px;
height:24px;
  @include media("<=phone") {
    width: 16px;
    height:16px;
  }
}

html:not(html:hover) .cursor::after {
  opacity: 0;
  transform: scale(0);
}

// Locomotive

html.has-scroll-smooth {
  overflow: hidden;
}

html.has-scroll-dragging {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.has-scroll-smooth body {
  overflow: hidden;
}

.has-scroll-smooth [data-scroll-container] {
  min-height: 100vh;
}

.c-scrollbar {
  position: absolute;
  right: 0;
  top: 0;
  width: 11px;
  height: 100vh;
  transform-origin: center right;
  transition: transform 0.3s, opacity 0.3s;
  opacity: 0;
}
.c-scrollbar:hover {
  transform: scaleX(1.45);
}
.c-scrollbar:hover,
.has-scroll-scrolling .c-scrollbar,
.has-scroll-dragging .c-scrollbar {
  opacity: 1;
}

.c-scrollbar_thumb {
  position: absolute;
  top: 0;
  right: 0;
  background-color: black;
  opacity: 0.5;
  width: 7px;
  border-radius: 10px;
  margin: 2px;
  cursor: -webkit-grab;
  cursor: grab;
  display: none;
}
.has-scroll-dragging .c-scrollbar_thumb {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
.showM{
  display:none;
  @include media("<=phone") {
    display:flex;
   }
}
.showD{
  display:flex;
  @include media("<=phone") {
    display:none;
   }
}

.overflow{
  overflow: hidden;
}