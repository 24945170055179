.menu {
    width: 33px;
    height: 33px;
    padding: 5px;
    display: block;
    cursor: pointer;
    position: relative;
    float: right;
    right: 50px;
    z-index: 1;
  }
  
  .menu span {
    cursor: pointer;
    height: 2px;
    width: 33px;
    margin-bottom: 8px;
    background: #000;
    position: relative;
    right: 0;
    display: block;
    transform: rotate(0deg);
    transition: .7s ease;
  }
  
  .hidden {
    opacity: 0;
    transition-delay: .5s;
    pointer-events: none;
    cursor: default;
  }
  
  .visible {
    opacity: 1;
  }
  
  .menu.open span:nth-child(1) {
    top: 10px;
    transform: rotate(135deg);
    transition: .7s ease;
    background: #fff;
  }
  
  .menu.open span:nth-child(2) {
    opacity: 0;
    right: 100px;
    background: #fff;
  }
  
  .menu.open span:nth-child(3) {
    top: -10px;
    transform: rotate(-135deg);
    transition: .7s ease;
    background: #fff;
  }
  
  #navigation {
    background: #64b5f6;
    font-family: 'Merriweather', serif;
    color: #fff;
    font-weight: 400;
    font-size: 40px;
    width: 100vw;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
  }
  .nav {
    display: flex;
    justify-content: center;
  }
  
  .slideInLeft {
    animation-duration: .5s;
  }
  
  .slideOutLeft {
    animation-duration: .5s;
  }
  
  ul {
    list-style-type: none;
  }
  ul li a {
    font-family: 'Merriweather', serif;
    color: #fff;
    text-decoration: none;
  }
  