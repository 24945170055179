%padding-home {
  padding-left: 4rem;
  padding-right: 4rem;

  @include media("<=phone") {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
}

.home {
  padding: 2.4rem 0;
  visibility: hidden;
  &__nav {
    @extend %padding-home;
    margin-bottom: 2.4rem;
    display: flex;
    justify-content: space-between;
    @extend %description-12;
    line-height: 1.4;

    .hide-mobile {
      @include media("<=phone") {
        display: none;
      }
    }

    .hide-desktop {
      @include media(">phone") {
        display: none;
      }
    }

    .nav__button {
      flex-shrink: 0;
      flex-grow: 0;
    }

    &__left,
    &__right {
      text-transform: uppercase;
      justify-content: space-between;

      @include media(">phone") {
        display: flex;
      }
    }

    &__left {
      max-width: 100%;
      width: 100%;
z-index: 4;
      @include media("<=phone") {
       display: flex;
        max-width: unset;
      }
      .nav__logo{

        @include media("<=phone") {
          width: 70%;
         }
      }
    }

    &__right {
      max-width: 325px;
      width: 100%;

      @include media("<=phone") {
        max-width: 30%;
        width: 30%;
        margin-left: auto;

        br {
          display: none;
        }
      }
    }

    .nav {
      &__folio,
      &__availability {
        color: $col-boulder;

        @include media("<=phone") {
          margin-bottom: 2.4rem;

          br {
            display: none;
          }
        }
      }
    }
  }

  &__hero {
    margin-bottom: 9.6rem;
    height:80vh;
    @include media("<=phone") {
      margin-top: 20%;
     }
    &__top{
width:100%;
display:flex;
padding:2.5% 5%
;
@include media("<=phone") {
 flex-direction: column;
}

      &__left{
width:30%;
display:flex;
position: relative;
    top: 4em;
    left: 2.5em;
    @include media("<=phone") {
      width:100%;
      left:0;
    }

    // @include media(">tablet") {
    //   text-align: center;
    // }
.welcome{
  opacity: 0;
  transform: translateY(100px);
  transform-origin: bottom;
}

.head-one{
  font-size: 7em;
    letter-spacing: -15px;
    font-weight: 900;
    opacity: 0;
    transform: translateY(100px);
    transform-origin: bottom;
    @include media("<=phone") {
      font-size: 6.5em;
      letter-spacing: -12.5px;
    }
    @include media("<=small") {
      font-size: 5.5em;
      letter-spacing: -14px;
    }
}
.head-two{
    letter-spacing: -2.5px;
    opacity: 0;
    transform: translateY(100px);
    transform-origin: bottom;
    @include media("<=phone") {
      letter-spacing: -3.5px;
    }
    @include media("<=small") {
      letter-spacing: -2.5px;
      font-size: 0.8em;
    }
}
.head-third{
  width: 80%;
  margin: 5% 0;
  opacity: 0;
  letter-spacing: -0.1px;
  transform: translateY(100px);
  transform-origin: bottom;
  p{
    font-size: 0.7em;
    @include media("<=phone") {
      width: 100%;
      font-size: 1.14em; 
    }
    @include media("<=small") {
      font-size: 0.7em 
     }
  }
}
      }
      &__right{
width:70%;
display:flex;
@include media("<=phone") {
  width:100%;
}

      }
    }
    &__bottom{
      width:100%;
      display:flex;
      padding:5%
      ;
      @include media("<=phone") {
        padding: 15% 2%;
        flex-direction:column
      }
            &__left{
      width:40%;
      display:flex;
      padding:0.5% 10.5% 0 3%;
      font-size:0.7em;
      @include media("<=phone") {
        width: 100%;
        font-size: 1.14em; 
      }
      @include media("<=small") {
        font-size: 0.7em !important;;
       }
    }
            &__right{
              width:60%;
              padding:0 0% 0 8%;
              position: relative;
              bottom: 0;
              @include media("<=phone") {
                width: 100%;
                padding: 2.5%;
              }
              p{
                font-size: 0.8em !important;
                padding: 0 15%;
                @include media("<=phone") {
                 font-size: 1.14em !important;
                 padding: 0;
                }
                @include media("<=small") {
                  font-size: 0.8em;
                }
              }
                    }
                  }
    @extend %padding-home;

    .hero {
      &__title {
        line-height: 0.9;
        color: $col-boulder;

        h1 {
          &.desktop {
            @include media("<=phone") {
              display: none;
            }
          }

          &.mobile {
            @extend %title-328;
            letter-spacing: -0.05em;
            word-wrap: break-word;
            @include media(">phone") {
              display: none;
            }
          }
        }

        .hero__hover {
          transition: color 0.2s;

          @media (any-pointer: fine) {
            &:hover {
              color: $col-gray;
            }
          }
        }

        &__left,
        &__right,
        &__dash {
          display: inline-block;
          @extend %title-328;
          letter-spacing: -0.105em;
        }

        &__dash {
          width:5%;
          transform: scaleX(1.05);
          will-change: transform;

          &.desktop {
            @include media("<=tablet") {
              display: none;
            }
          }

          &.tablet {
            @include media(">tablet") {
              display: none;
            }
          }
        }

        &__top {
          display: flex;
          justify-content: space-between;
          padding-right: 3rem;
        }

        &__top{ width: 70%;}
        &__bottom {
          width: 100%;

          &.overflow {
            display: inline-flex;
            width: 100%;
            overflow: hidden;
          }
        }

        &__bottom {
          justify-content: space-between;

          @include media(">tablet") {
            display: flex;
          }
        }
        .bottom__left {
          @extend %title-328;
          letter-spacing: -0.105em;
          flex-shrink: 0;
        }

        .bottom__right {
          @extend %description-16;
          position: relative;
          z-index: 1;
          max-width: 32.5rem;
          width: 100%;
          padding: 2rem 0 5rem;
          display: flex;
          flex-direction: column;

          @include media("<=tablet") {
            max-width: 55rem;
            padding-bottom: 0;
          }

          @include media("<=phone") {
            max-width: 255px;
            margin-left: auto;
          }
        }
      }

      &__paragraph {
        color: $col-gray;
transform: translateY(70px);
opacity:0;
        span {
          text-transform: uppercase;
        }

        .first-word {
          @extend %description-12;
          color: #d33a2b;
        }
      }
      .first-word {
        @extend %description-12;
        color: #d33a2b;
      }
      &__scroll {
        margin-top: auto;
        color: $col-boulder;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include media("<=phone") {
          margin-top: 20%;
         }
        // @include media("<=tablet") {
        //   display: none;
        // }
      }
    }
  }

  &__projects {
    @extend %padding-home;
    .flex_D{
      display:flex;
      @include media("<=phone") {
     flex-direction: column;
       }
    }
    .reveal {
  visibility: hidden;
  position: relative;
  width:2em;
  height:25vh;
  max-width: 2em;
  overflow: hidden;
  margin-left: 5%;
  @include media("<=phone") {
    max-width: 100%;
    width:100% ;
    height: 30vh;
    bottom: 45%;
    margin-left: 0;
   }
}
    .project__img{
width:100%;
background-size: cover;
background-blend-mode: overlay;
background: #241d1de3;
transform-origin: left;
height:100%;
@include media("<=phone") {
 width:100%;
}

}
    &__line {
      position: relative;
      display: inline-block;
      width: 100%;
      visibility: hidden;
      span {
        height: 2px;
        background: $col-boulder;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        display: inline-block;
        transform-origin: left top;
      }

      &.right {
        span {
          transform-origin: right top;
        }
      }
    }
  }

  &__content {
    padding-top: 24rem;
    padding-bottom: 24rem;
    @extend %padding-home;

    @include media("<=phone") {
      padding-top: 16rem;
      padding-bottom: 16rem;
    }

    @include media(">tablet") {
      text-align: center;
    }

    &__title {
      @extend %description-12;
      text-transform: uppercase;
      color: $col-boulder;
      margin-bottom: 2.4rem;
    }

    &__desc {
      @extend %description-32;
      max-width: 95rem;
      width: 100%;
      @extend %links;

      @include media(">tablet") {
        margin: 0 auto;
      }

      .contact-scroll {
        color: $col-boulder;
        transition: color 0.3s;
        background: transparent;
        border: 0;
        padding: 0;
        @extend %description-32;
        font-weight: 500;
        cursor: pointer;

        @media (any-pointer: fine) {
          &:hover {
            color: $col-orange;
          }
        }
      }
    }
  }

  &__stack {
    max-width: 104.4rem;
    margin-left: auto;
    padding-top: 16rem;
    padding-bottom: 24rem;
    @extend %description-16;

    @include media("<=phone") {
      padding-top: 9.6rem;
      padding-bottom: 16rem;
    }

    &__paragraph {
      color: $col-gray;
      max-width: 32.5rem;

      @include media("<=tablet") {
        max-width: 55rem;
      }

      @include media("<=phone") {
        max-width: 35rem;
        margin-left: auto;
        padding-left: 1.6rem;
        padding-right: 1.6rem;
      }

      span,
      a {
        color: $col-boulder;
        text-transform: uppercase;
      }

      @extend %links;

      .first-word {
        @extend %description-12;
      }
    }
  }

 

  &__contact {
    @include media(">tablet") {
      padding-top: 16rem;
    }

    .line-wrapper {
      @extend %padding-home;
    }

    &__desc {
      @extend %description-16;
      @extend %padding-home;
      margin: 0;
      margin-bottom: 6.4rem;

      @include media(">phone") {
        max-width: 44rem;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        padding: 0;
      }
    }



    &__email {
      padding-top: 4.8rem;
      padding-bottom: 7.2rem;
      text-align: center;

      @include media("<=phone") {
        max-width: 100%;
        padding:3% 10% ;
        width: 100%;
        text-align: left;
        margin-left: auto;
        word-break: break-word;
      }

      

      .email {
        @extend %description-32;
        background: transparent;
        border: 0;
        font-weight: 500;
        cursor: pointer;
        color: $col-gray;
        padding: 0;
        margin-bottom: 1.6rem;

        @include media("<=tablet") {
          text-align: left;
        }

        @media (any-pointer: fine) {
          &:hover {
            color: red;
          }
        }

        &:hover {
          & + .to-copy {
            transform: translateY(0);
            opacity: 1;
          }
        }
      }
    }
    &__phone {
      padding-top: 4.8rem;
      padding-bottom: 7.2rem;
      text-align: center;

      @include media("<=phone") {
        max-width: 100%;
        padding: 3% 10%;
        width: 100%;
        text-align: left;
        margin-left: auto;
        word-break: break-word;
      }
      .phone {
        @extend %description-32;
        background: transparent;
        border: 0;
        font-weight: 500;
        cursor: pointer;
        color: $col-gray;
        padding: 0;
        margin-bottom: 1.6rem;

        @include media("<=tablet") {
          text-align: left;
        }

        @media (any-pointer: fine) {
          &:hover {
            color: red;
          }
        }

        &:hover {
          & + .to-copy {
            transform: translateY(0);
            opacity: 1;
          }
        }
      }
  }
  &__address{
    padding-top: 4.8rem;
    padding-bottom: 7.2rem;
    text-align: center;

    @include media("<=phone") {
      max-width: 100%;
      padding: 3% 10%;
      width: 100%;
      text-align: left;
      margin-left: auto;
      word-break: break-word;
    }
    .address {
      @extend %description-32;
      background: transparent;
      border: 0;
      font-weight: 500;
      cursor: pointer;
      color: $col-gray;
      padding: 0;
      margin-bottom: 1.6rem;

      @include media("<=tablet") {
        text-align: left;
      }

      @media (any-pointer: fine) {
        &:hover {
          color: red;
        }
      }

      &:hover {
        & + .to-copy {
          transform: translateY(0);
          opacity: 1;
        }
      }
    }
  }
  }

  &__footer {
    justify-content: center;
    position: relative;
    padding-bottom: 4rem;
    font-weight: 400;
    font-size: 16px;

    @include media(">tablet") {
      display: flex;
    }

    @include media("<=tablet") {
      margin-left: auto;
      max-width: 40rem;
      width: 100%;
    }

    @include media("<=phone") {
      max-width: 255px;
      width: 100%;
    }

    &__left {
      display: flex;
      font-family: $font-sauce;
      @include media(">tablet") {
        left: 2.4rem;
        position: absolute;
        right: 0;
        top: 1rem;
      }

      .place {
        color: $col-boulder;
        margin-right: 1.4rem;
      }

      .time {
        position: relative;

        &::before {
          content: "";
          position: absolute;
          background: $col-black;
          height: 100%;
          left: 0;
          width: 6px;
        }

        @include media("<=tablet") {
          margin-bottom: 2.4rem;
        }
      }
    }

    &__right {
      @extend %links;

      @include media(">tablet") {
        right: 2.4rem;
        position: absolute;
        top: 1rem;
      }
    }

    &__center {
      .footer__links {
        @include media(">tablet") {
          display: flex;
        }

        align-items: center;

        @include media("<=tablet") {
          margin-bottom: 4.8rem;
        }
        .link__flex {
          display: flex;
          margin-left: auto;

          @include media("<=tablet") {
            max-width: 40rem;
            width: 100%;
          }

          @include media("<=phone") {
            max-width: 255px;
            width: 100%;
          }

          &__inner {
            display: flex;
            .c-button {
              @include media("<=tablet") {
                &:first-of-type {
                  margin-right: 0.8rem;
                }
              }
            }
            &.second {
              .c-button {
                @include media("<=tablet") {
                  &:first-of-type {
                    margin-right: 0;
                  }
                  &:last-of-type {
                    margin-right: 0.8rem;
                  }
                }
              }
            }

            @include media(">tablet") {
              &:last-of-type {
                margin-left: 0.8rem;
              }
            }
          }

          @include media("<=tablet") {
            &__inner {
              &.second {
                margin-top: 1.6rem;
                flex-direction: row-reverse;
              }
            }
          }
        }

        .c-button {
          @include media(">tablet") {
            &:not(:last-of-type) {
              margin-right: 0.8rem;
            }
          }
        }
      }
    }
  }
}
