/* COLOURS */

$colour-cyan: #d62e1e;
$colour-white: #FFFFFF;
$colour-black: #151313;


@mixin animation ($animation) {
	-webkit-animation: $animation;
	-moz-animation: $animation;
	-ms-animation: $animation;
	-o-animation: $animation;
	animation: $animation;
}

@mixin transition ($transition) {
	-webkit-transition: $transition;
	-moz-transition: $transition;
	-ms-transition: $transition;
	-o-transition: $transition;
	transition: $transition;
}

@mixin transform ($transform) {
	-webkit-transform: $transform;
	-moz-transform: $transform;
	-ms-transform: $transform;
	-o-transform: $transform;
	transform: $transform;
}

/* GLOBE */

.globe {
	position: absolute;
	width: 100vw;
	height: 90vh;
    left:20%;
    top:10vh;
}

.globe .globe-list {
	z-index: 10;
	position: absolute;
	left: 0;
	top: 0;
	list-style: none;
	display: none;
}

$dotSize: 16px;
$dotSizeLarge: $dotSize + ($dotSize / 2);

.globe .globe-list {
	opacity: 2;
    width: 80%;
    display: flex;
    position: absolute;
    top: 5em;
    /* margin: 5%; */
    padding: 5% 0;
    left: 35%;
	@include transition(opacity 3s cubic-bezier(0.175, 0.885, 0.320, 1.275));
    span{
        margin: -5%;
        background: none;
        font-size: 2em;
       
    }
}

.globe .globe-list.active {
	opacity: 1;
}

#value{
	opacity:0;
	transform: translateY(90px);
}
.globe .globe-list > li {
	opacity: 0.0;
	position: absolute;
    font-size: 1.2em;
    overflow:hidden;
	margin:4% -5%;
    display:flex;
    height: 10vh;
	#excel{
		opacity: 0.0;
	}
	#inter{
		opacity: 0.0;
		margin:10% -1% !important;
	
	}
	#customer-s{
		opacity: 0.0;
	
	}
span{
    opacity: 0.4;
	width: $dotSize;
	height: $dotSize;
	border-radius: 50%;
	// background: $colour-cyan;
	// @include transition(opacity 1s cubic-bezier(0.175, 0.885, 0.320, 1.275));
}
h1{
    transform: translateY(-20px);
}
&:nth-child(1){
    margin:10% 7%;
}
&:nth-child(2){
    margin:5% 35%;

}
&:nth-last-child(1){
    margin:14% 15%;

}
}

.globe .globe-list > li.active {
	opacity: 1;
	margin-left: -($dotSizeLarge / 2);
	margin-top: -($dotSizeLarge / 2);
	width: $dotSizeLarge;
	height: $dotSizeLarge;
}

.globe .globe-list > li:before {
	content: "";
	opacity: 0.5;
	pointer-events: none;
	position: absolute;
	left: 50%;
	top: 20%;
	margin-left: -($dotSize / 2);
	margin-top: -($dotSize / 2);
	width: 25px;
	height: 25px;
	border-radius: 50%;
	background: $colour-cyan;
	@include animation(2s pulse infinite linear);
}

@-webkit-keyframes pulse {
	0% { -webkit-transform: scale(1); }
	50% { opacity: 0.5; }
	100% { opacity: 0; -webkit-transform: scale(2); }
}

@-moz-keyframes pulse {
	0% { -moz-transform: scale(1); }
	50% { opacity: 0.5; }
	100% { opacity: 0; -moz-transform: scale(2); }
}

@-ms-keyframes pulse {
	0% { -ms-transform: scale(1); }
	50% { opacity: 0.5; }
	100% { opacity: 0; -ms-transform: scale(2); }
}

@-o-keyframes pulse {
	0% { -o-transform: scale(1); }
	50% { opacity: 0.5; }
	100% { opacity: 0; -o-transform: scale(2); }
}

@keyframes pulse {
	0% { transform: scale(1); }
	50% { opacity: 0.5; }
	100% { opacity: 0; transform: scale(2); }
}

.globe .globe-list > li.active:before {
	margin-left: -($dotSizeLarge / 2);
	margin-top: -($dotSizeLarge / 2);
	width: $dotSizeLarge;
	height: $dotSizeLarge;
}

.globe .globe-list > li.active,
.globe .globe-list > li.active:before {
	background: $colour-white;
}

.globe .globe-list .text {
	position: absolute;
	opacity: 0.8;
	right: $dotSize + 5px;
	top: 50%;
	display: block;
	font-size: 14px;
	line-height: 14px;
	font-weight: 600;
	text-align: right;
	text-shadow: -1px -1px 0 $colour-black, 1px -1px 0 $colour-black, -1px 1px 0 $colour-black, 1px 1px 0 $colour-black;
	color: $colour-white;
	white-space: nowrap;
	@include transform(translateY(-50%));
}

.globe .globe-list > li.active .text {
	opacity: 1;
	right: $dotSizeLarge + 5px;
	font-size: 20px;
	line-height: 20px;
	font-weight: 700;
}

.globe .globe-canvas {
	z-index: 1;
	position: absolute;
	left: 0%;
	top: 0;
}

.globe svg{
	height: 100%;
	width: 100%;
	opacity: .3;
	animation: rotation 10s linear infinite
}

.svg-wrapper{
	opacity: 0;
	display: inline-block;
	position: absolute;
	width: 80vh;
	height: 80%;
	left: 0;
	right: 0;
	top: 10%;
	margin: auto;
	transform: scale(0.5);
	transition: opacity 1s ease-out 1.6s, transform 1s ease-out 1.6s;
	&.active{
		opacity: 1;
		transform: none
	}
}

@keyframes rotation {
	0%{
		transform: scaleX(-1) rotate(360deg)
	}
	100%{
		transform: scaleX(-1) rotate(0deg)
	}
}
